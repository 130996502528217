<script lang="ts" setup>
import { computed } from "vue";
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { SingleChoiceScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import generators from "~/resources/onboarding/generators";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import AppAlertDanger from "~/components/app/AppAlertDanger.vue";
import { useOnboardingConditions } from "~/composables";

interface Props {
  screen: SingleChoiceScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = useOnboardingConditions(props);

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();
const { addActionEvent } = useTrackingStore();
const { t } = useNuxtApp().$i18n;
const useGridLayout = computed(() => props.screen.optionsLayout === "Grid");

function selectOption(option: SingleChoiceScreen["options"][number]) {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(option.nextStep),
  });

  addActionEvent({
    name: stripScreenIdPrefix(props.screen.id),
    selection: [option.trackingName],
    screenType: props.screen.type,
  });
}

const { $pinia } = useNuxtApp();

const { multiChoiceSelectedOptions, singleChoiceSelectedOptions } = useOnboardingStore($pinia);

const _screen = computed(() => {
  if (!props.screen.generatorFunctionName) {
    const filteredOptions = props.screen.options?.filter((option) => {
      return option.visible ? getOrGetConditionalValue(option.visible) : true;
    });

    return { ...props.screen, options: filteredOptions };
  }

  const generator = generators[props.screen.generatorFunctionName];

  const result = generator({
    registrationParams: props.registrationParams,
    multiChoiceSelectedOptions,
    singleChoiceSelectedOptions,
  });

  if (!result) {
    // Todo: Log to Sentry
    return null;
  }

  return {
    ...props.screen,
    titleTranslationKey: result.titleTranslationKey,
    captionTranslationKey: result.captionTranslationKey,
    options: result.options,
  } as SingleChoiceScreen;
});

const title = computed(() => {
  if (!_screen.value?.titleTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(_screen.value?.titleTranslationKey));
});

const caption = computed(() => {
  if (!_screen.value?.captionTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(_screen.value?.captionTranslationKey));
});
</script>

<template>
  <div
    v-if="_screen"
    :class="{
      'flex flex-col gap-16': useGridLayout,
    }"
  >
    <h1>
      {{ title }}
    </h1>
    <span v-if="caption" class="mb-4 block">{{ caption }}</span>
    <div
      class="grid gap-4"
      :class="{
        'grid-cols-2': useGridLayout,
      }"
    >
      <OnboardingBtn
        v-for="(option, key) in _screen.options"
        :key="key"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        :description-translation-key="option.descriptionTranslationKey"
        :is-column="useGridLayout"
        @click="selectOption(option)"
      />
    </div>
  </div>
  <AppAlertDanger v-else class="my-3"> {{ $t(`system.general.message.unknown_error`) }} </AppAlertDanger>
</template>
