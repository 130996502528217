<script lang="ts" setup>
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

import { onboardingPartner } from "~/resources/onboarding/onboarding-partner";
import { onboardingShort } from "~/resources/onboarding/onboarding-short";
import { onboardingTest } from "~/resources/onboarding/onboarding-test";
import { onboardingWelcome } from "~/resources/onboarding/onboarding-welcome";
import { onboardingCalculator } from "~/resources/onboarding/onboarding-calculator";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import AppProgress from "~/components/app/AppProgress.vue";
import OnboardingDiscountBannerSecondPartnerScreen from "~/components/onboarding/OnboardingDiscountBannerSecondPartnerScreen.vue";
import type { Instructions } from "~/types/onboarding/onboardingScreen";
import { stripScreenIdPrefix } from "~/utils/text";

const { enabledFunnels } = useAppConfig();
const { t } = useNuxtApp().$i18n;
const featureFlags = useFeatureFlags();

useHead({
  title: t("app.prosignup.seo_title"),
});

definePageMeta({
  key: "onboarding",
  middleware: ["redirect-authenticated-users", "check-calculator-parameters"],
});

const defaultInstructionsIdentifier = "start";
const onboardingStore = useOnboardingStore();
const route = useRoute();

const { addImpressionEvent } = useTrackingStore();
const { skippedOnboarding } = storeToRefs(onboardingStore);

const {
  updateRegistrationParams,
  screen,
  screenComponent,
  registrationParams,
  screenShouldBeReplaced,
  screenHasWideWrapper,
  screenUseTransparentBackground,
  screenIndex,
  getProgress,
} = useOnboarding(await getInstructions());

async function getInstructions() {
  const funnels: Record<string, Instructions> = {
    partner: onboardingPartner,
    short: onboardingShort,
    test: onboardingTest,
    welcome: onboardingWelcome,
    calculator: onboardingCalculator,
  };

  if (!route.params.instructions || route.params.instructions === defaultInstructionsIdentifier) {
    return funnels.short;
  }

  const requestedFunnel: string = Array.isArray(route.params.instructions)
    ? route.params.instructions[0]
    : route.params.instructions;

  if (!enabledFunnels.includes(requestedFunnel) || !funnels[requestedFunnel]) {
    await navigateTo("/onboarding");
    return funnels.short;
  }

  return funnels[requestedFunnel];
}

const { skipPersonalizedMotivationScreen, skipBenefitScreen } = useAB();

async function doNext({ nextScreenId, params }: { nextScreenId: string; params: Partial<RegistrationParams> }) {
  const currentScreenId = screen.value.id;
  const replace = screenShouldBeReplaced(currentScreenId);

  updateRegistrationParams(params);

  if (nextScreenId === "screenid:onboarding.encouraging_flow.personal.personalized_question") {
    if (skipPersonalizedMotivationScreen.value) {
      nextScreenId = "screenid:onboarding.encouraging_flow.plan.load_plan_1";
    }
  }

  if (nextScreenId === "screenid:onboarding.encouraging_flow.plan.benefits") {
    if (skipBenefitScreen.value) {
      nextScreenId = "screenid:end";
    }
  }

  if (nextScreenId === "screenid:end") {
    updateRegistrationParams({ incomplete: false });

    return navigateTo({
      path: "/onboarding/checkout",
      query: {
        instructions: route.params.instructions || defaultInstructionsIdentifier,
      },
      replace,
    });
  }

  const nextScreenSlug =
    Object.keys(onboardingScreenSlugs).find((key) => onboardingScreenSlugs[key] === nextScreenId) || nextScreenId;

  await navigateTo({
    path: `/onboarding/${route.params.instructions || defaultInstructionsIdentifier}/${nextScreenSlug}`,
    replace,
  });
}

function trackScreenImpression() {
  addImpressionEvent({ name: stripScreenIdPrefix(screen.value.id) });
}

onMounted(() => {
  skippedOnboarding.value = false;
  trackScreenImpression();
});

watch(screen, () => {
  trackScreenImpression();
});

async function skip() {
  registrationParams.value = {
    ...registrationParams.value,
    goal: "lose",
    unitMass: "kg",
    weightGoal: 60,
    startWeight: 70,
    unitLength: "cm",
    bodyHeight: 170,
    sex: "female",
    dateOfBirth: "2000-01-01",
    incomplete: true,
  };

  skippedOnboarding.value = true;

  if (featureFlags.benefits_screen.isOn().value) {
    await doNext({ nextScreenId: "screenid:onboarding.encouraging_flow.plan.benefits", params: {} });
  } else {
    await navigateTo(`${useRuntimeConfig().app.baseURL}/onboarding/checkout`, { external: true });
  }
}
</script>

<template>
  <transition name="fade" mode="out-in">
    <div
      :key="screenHasWideWrapper.toString()"
      class="flex flex-1 flex-col"
      :class="{
        'bg-mobile-blurred-background bg-cover bg-top sm:bg-desktop-blurred-background': screenUseTransparentBackground,
      }"
    >
      <div class="flex flex-1 flex-col">
        <OnboardingDiscountBannerSecondPartnerScreen
          v-if="route.params.instructions === 'partner' && screenIndex === 1"
        />

        <div
          class="flex flex-1 justify-center"
          :class="[screenHasWideWrapper ? 'mx-auto w-full pb-4' : 'container px-bs-container']"
        >
          <section
            class="flex flex-col pb-5"
            :class="[screenHasWideWrapper ? 'w-full max-w-full' : 'w-full max-w-full lg:w-7/12']"
          >
            <AppProgress
              v-if="!screenUseTransparentBackground && !screenHasWideWrapper"
              :progress="getProgress()"
              class="mt-5"
              @skip="skip"
            />
            <transition name="fade" mode="out-in">
              <component
                :is="screenComponent"
                :screen="screen"
                :registration-params="registrationParams"
                :has-wide-wrapper="screenHasWideWrapper"
                class="flex-1"
                @next="doNext"
                @skip="skip"
              />
            </transition>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>
