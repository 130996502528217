<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { OverallGoalScreen } from "~/types/onboarding/onboardingScreen";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";
import OnboardingCalculatorBanner from "~/components/onboarding/OnboardingCalculatorBanner.vue";
import OnboardingCalculatorDisclaimer from "~/components/onboarding/OnboardingCalculatorDisclaimer.vue";
import { useOnboardingConditions } from "~/composables";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { stripScreenIdPrefix } from "~/utils/text";

interface Props {
  screen: OverallGoalScreen;
  registrationParams: RegistrationParams;
}

const { $pinia } = useNuxtApp();
const props = defineProps<Props>();
const onboardingStore = useOnboardingStore($pinia);

interface Emits {
  (
    e: "next",
    value: {
      params: { reason: GoalTypeAbbr | null };
      nextScreenId: string;
    },
  ): void;
}

const { t } = useNuxtApp().$i18n;
const emit = defineEmits<Emits>();
const { addActionEvent } = useTrackingStore();
const calculatorFunnelStore = useCalculatorFunnelStore();
const { getOrGetConditionalValue } = useOnboardingConditions(props);

type GoalOption = {
  emoji: string;
  translationKey: string;
  trackingName: string;
  goal: GoalTypeAbbr | null;
};

const options: GoalOption[] = [
  {
    emoji: "\uD83D\uDCC9",
    translationKey: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
    trackingName: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
    goal: "lose",
  },
  {
    emoji: "\uD83D\uDC40",
    translationKey: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
    trackingName: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
    goal: "maintain",
  },
  {
    emoji: "\uD83D\uDCC8",
    translationKey: "onboarding.encouraging_flow.goal.main_goal.gain_weight",
    trackingName: "onboarding.encouraging_flow.goal.main_goal.gain_weight",
    goal: "gain",
  },
  {
    emoji: "\uD83D\uDCAA",
    translationKey: "onboarding.encouraging_flow.goal.main_goal.build_muscle",
    trackingName: "onboarding.encouraging_flow.goal.main_goal.build_muscle",
    goal: "build_muscle",
  },
];

const fallbackOption: GoalOption = {
  emoji: "💬",
  translationKey: "onboarding.encouraging_flow.goal.main_goal.other",
  trackingName: "onboarding.encouraging_flow.goal.main_goal.other",
  goal: null,
};

const sortedOptions = computed(() => {
  const recommendedGoal = calculatorFunnelStore.getRecommendedGoal();
  if (!recommendedGoal) {
    return options;
  }

  return options.sort((a, b) => {
    if (a.goal === recommendedGoal) {
      return -1;
    }

    if (b.goal === recommendedGoal) {
      return 1;
    }

    return 0;
  });
});

function getStatusText(translationKey: string) {
  const translationKeyMap: Record<string, GoalTypeAbbr> = {
    "onboarding.encouraging_flow.goal.main_goal.lose_weight": "lose",
    "onboarding.encouraging_flow.goal.main_goal.maintain_weight": "maintain",
    "onboarding.encouraging_flow.goal.main_goal.gain_weight": "gain",
  };

  if (
    calculatorFunnelStore.getRecommendedGoal() &&
    calculatorFunnelStore.getRecommendedGoal() === translationKeyMap[translationKey]
  ) {
    return t("app.profunnel.calculator_funnel.bmi.recommended");
  }
}

function selectOption(option: GoalOption) {
  onboardingStore.registrationParams.reason = option.goal;

  emit("next", {
    params: { reason: option.goal },
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });

  addActionEvent({
    name: stripScreenIdPrefix(props.screen.id),
    selection: [option.trackingName],
    screenType: props.screen.type,
  });
}

const title = computed(() => {
  if (!props.screen.titleTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(props.screen.titleTranslationKey));
});
</script>

<template>
  <div>
    <OnboardingCalculatorBanner class="mt-4" />

    <h1>
      {{ title }}
    </h1>
    <div class="mb-4 grid gap-4">
      <OnboardingBtn
        v-for="(option, key) in sortedOptions"
        :key="key"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        :status-text="getStatusText(option.translationKey)"
        @click="selectOption(option)"
      />
      <OnboardingBtn
        v-if="screen.showElseOption"
        :emoji="fallbackOption.emoji"
        :translation-key="fallbackOption.translationKey"
        @click="selectOption(fallbackOption)"
      />
    </div>

    <OnboardingCalculatorDisclaimer class="my-5" />
  </div>
</template>
