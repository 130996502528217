import personalizedMotivationScreen from "./personalized-motivation-screen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import type { SingleChoiceScreen } from "~/types/onboarding/onboardingScreen";

export interface GeneratorInput {
  registrationParams: Partial<RegistrationParams>;
  multiChoiceSelectedOptions: { [key: string]: string[] };
  singleChoiceSelectedOptions: { [key: string]: string };
}

export default { personalizedMotivationScreen } as Record<string, (data: GeneratorInput) => Partial<SingleChoiceScreen> | undefined>;
