<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import OnboardingDiscountBanner from "~/components/onboarding/OnboardingDiscountBanner.vue";
import OnboardingSkipButton from "~/components/onboarding/OnboardingSkipButton.vue";
import OnboardingStylingChanges from "~/components/onboarding/utils/OnboardingStylingChanges.vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { useFunnelStore } from "~/stores/useFunnelStore";
import OnboardingFoodBanner from "~/components/onboarding/OnboardingFoodBanner.vue";

type Props = {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
  showDiscountBanner?: boolean;
  showSkipButtons?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showDiscountBanner: false,
  showSkipButtons: true,
  screen: undefined,
  registrationParams: undefined,
});

const { getOrGetConditionalValue } = useOnboardingConditions(props);
const isAlternativeFunnel = useFunnelStore().isAlternativeVariant();

const skipButtonActive = props.showSkipButtons && !isAlternativeFunnel;
const showHeader = isAlternativeFunnel;

const { singleChoiceSelectedOptions } = storeToRefs(useOnboardingStore());

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
  (e: "skip"): void;
}

const emit = defineEmits<Emits>();

function selectOption(range: string) {
  singleChoiceSelectedOptions.value["age-range"] = range;
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <div>
    <OnboardingStylingChanges
      use-transparent-background
      hide-progress
      :hide-header="!showHeader"
    ></OnboardingStylingChanges>
    <OnboardingSkipButton v-if="skipButtonActive" :style="'close'" @skip="$emit('skip')"></OnboardingSkipButton>

    <div class="mt-8 flex flex-col items-center gap-8">
      <img
        v-if="!showHeader"
        src="https://assets.yazio.com/frontend/images/web-funnels/layout/yazio-logo-rounded.svg"
        alt="YAZIO Logo"
        height="36"
        class="h-9"
      />
      <div v-else class="mt-12"></div>

      <OnboardingDiscountBanner v-if="showDiscountBanner" />
      <OnboardingFoodBanner />

      <div class="flex flex-col items-center gap-3">
        <h1 class="m-0 mb-1.5 text-center text-white">
          {{ $t("text.webfunnel.age_group") }}
        </h1>

        <div class="flex flex-wrap justify-center gap-4">
          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_1"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-1.png"
            :full-width="false"
            @click="selectOption('16-29')"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_2"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-2.png"
            :full-width="false"
            @click="selectOption('30-49')"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_3"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-3.png"
            :full-width="false"
            @click="selectOption('50-69')"
          />

          <OnboardingBtn
            is-column
            translation-key="text.webfunnel.age_group_4"
            image="https://images.yazio.com/frontend/web-funnels/onboarding/illustrations/age-group-4.png"
            :full-width="false"
            @click="selectOption('70+')"
          />
        </div>
      </div>

      <OnboardingSkipButton v-if="skipButtonActive" @skip="$emit('skip')"></OnboardingSkipButton>
    </div>
  </div>
</template>
