<script setup lang="ts">
import AppIcon from "~/components/app/AppIcon.vue";

const discountStore = useDiscountStore();
const partnerContent = await discountStore.useAsyncPartnerContent();
const discount = await discountStore.useAsyncDiscount();

const { showPartnerFunnelSecondPageDiscountBanner } = useAB();
</script>

<template>
  <div>
    <div
      v-if="
        (discount.data.value !== 0 || (partnerContent.data.value && discount.data.value !== 0)) &&
        showPartnerFunnelSecondPageDiscountBanner
      "
      class="bg-yz-teal-100 py-4"
    >
      <div class="container flex items-center gap-2 px-bs-container lg:justify-center">
        <AppIcon icon="mdi:check-circle" width="1.3rem" class="text-yz-teal-600" />
        <div>
          <span
            v-if="partnerContent.data.value"
            v-html="
              $t('app.profunnel.funnel.exclusive_discount_from_activated', [
                discount.data.value,
                partnerContent.data.value.name,
              ])
            "
          /><span v-else v-html="$t('app.profunnel.funnel.exclusive_discount_activated', [discount.data.value])" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
:deep() strong {
  @apply font-medium !important;
}
</style>
