<script lang="ts" setup>
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import OnboardingAgeRange from "~/components/onboarding/screens/OnboardingAgeRange.vue";
import OnboardingHelloFresh from "~/components/onboarding/OnboardingHelloFresh.vue";

interface Props {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
}

defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
  (e: "skip"): void;
}

const emit = defineEmits<Emits>();

function doNext(value: { params: Partial<RegistrationParams>; nextScreenId: string }) {
  const { params, nextScreenId } = value;

  emit("next", {
    params,
    nextScreenId,
  });
}
</script>

<template>
  <div class="flex flex-col gap-8">
    <OnboardingAgeRange
      :screen="screen"
      :registration-params="registrationParams"
      show-discount-banner
      show-skip-buttons
      @next="doNext"
      @skip="$emit('skip')"
    />

    <p class="mb-12 flex flex-col items-center">
      <small class="text-white" v-html="$t('text.webfunnel.partnership.firstpage.existing_user')" />
      <NuxtLink to="/account/login">
        <small class="text-gray-200" v-html="$t('text.webfunnel.partnership.firstpage.existing_user_link')" />
      </NuxtLink>
    </p>

    <OnboardingHelloFresh />
  </div>
</template>
