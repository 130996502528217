<script lang="ts" setup>
import { ref } from "vue";
const config = useRuntimeConfig();
interface Props {
  images: string[];
  srcImageWidth: number;
  stackMaxWidth: number;
  alt: string;
  aspect?: "small" | "big";
}

const props = withDefaults(defineProps<Props>(), {
  aspect: "big",
});

const selectedKey = ref<number | null>();

function select(key: number) {
  selectedKey.value = key;
}

function fullUrl(imgSrc: string) {
  const src = imgSrc.replace(/\/plain\//, `/w:${props.srcImageWidth}/plain/`);
  return `${config.public.imageBaseUrl}/${src}`;
}
</script>

<template>
  <div
    class="relative block"
    :class="{
      'aspect-[1.35]': props.aspect === 'big',
      'aspect-[1.6]': props.aspect === 'small',
    }"
    :style="{
      maxWidth: stackMaxWidth ? `${stackMaxWidth}px` : undefined,
    }"
  >
    <img
      v-for="(src, key) in images"
      :key="key"
      :class="[
        'absolute max-w-[50%] rounded-xl shadow-xl',
        {
          'left-[52%] top-[5%] z-30 -translate-x-1/2 rotate-[4deg]': key === 0,
          'left-[30%] top-[20%] z-20 -translate-x-1/2 -rotate-12 scale-90': key === 1,
          'left-[68%] top-[27%] z-10 -translate-x-1/2 rotate-[16deg] scale-[0.84]': key === 2,
          'translate-y-2 lg:translate-y-5': props.aspect === 'small',
        },
      ]"
      :src="fullUrl(src)"
      :alt="alt"
      @click="select(key)"
    />
  </div>
</template>
