<script lang="ts" setup>
import { computed } from "vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { InfoListImageScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import OnboardingStylingChanges from "~/components/onboarding/utils/OnboardingStylingChanges.vue";

const config = useRuntimeConfig();
const { locale } = useI18n();

interface Props {
  screen: InfoListImageScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = useOnboardingConditions(props);
const { t } = useNuxtApp().$i18n;

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const imageUrl = computed(() => {
  const width = 1920;
  const src = getOrGetConditionalValue(props.screen.imageUrl).replace(/\/plain\//, `/w:${width}/plain/`);
  return `${config.public.imageBaseUrl}/${src}.${locale.value}.light.png`;
});

const transformImageUrl = (url: string) => {
  const src = url.replace(/\/plain\//, `/w:500/plain/`);
  return `${config.public.imageBaseUrl}/${src}.${locale.value}.light.png`;
};

function next() {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}

const title = computed(() => {
  if (!props.screen.titleTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(props.screen.titleTranslationKey));
});

const infoList = computed(() => {
  return props.screen.infoList.filter((option) => {
    return getOrGetConditionalValue(option.visible || true);
  });
});

const isWhyOtherDietsFailScreen = computed(() => {
  return props.screen.type === "why_other_diets_fail";
});
</script>

<template>
  <OnboardingStickyButtonWrapper :button-text="$t(screen.nextButtonTranslationKey)" @button-click="next">
    <OnboardingStylingChanges hide-progress></OnboardingStylingChanges>
    <div
      class="mb-5 flex flex-col"
      :class="{
        'gap-5': !isWhyOtherDietsFailScreen,
      }"
    >
      <h1>
        {{ title }}
      </h1>
      <img :src="imageUrl" alt="" class="mx-auto max-w-full md:max-w-md" />
      <div class="flex flex-col gap-10">
        <div v-for="(option, key) in infoList" :key="key" class="flex items-center gap-5">
          <span v-if="option.emoji" class="rounded-full bg-gray-200 p-3">{{ option.emoji }}</span>
          <div v-else-if="option.logo">
            <img v-if="option.logo === 'columbia'" src="../../../assets/images/columbia.svg" alt="" class="w-28" />
            <img v-else-if="option.logo === 'aasd'" src="../../../assets/images/aasd.png" alt="" class="w-28" />
            <img v-else :src="transformImageUrl(option.logo)" alt="" class="w-28" />
          </div>
          <div v-if="option.translationKey">{{ $t(option.translationKey) }}</div>
        </div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
