import { storeToRefs } from "pinia";
import type { Instructions, OnboardingScreen } from "~/types/onboarding/onboardingScreen";

import OnboardingSingleChoice from "~/components/onboarding/screens/OnboardingSingleChoice.vue";
import OnboardingMultiChoice from "~/components/onboarding/screens/OnboardingMultiChoice.vue";
import OnboardingAffirmation from "~/components/onboarding/screens/OnboardingAffirmation.vue";
import OnboardingSex from "~/components/onboarding/screens/OnboardingSex.vue";
import OnboardingBirthday from "~/components/onboarding/screens/OnboardingBirthday.vue";
import OnboardingHeight from "~/components/onboarding/screens/OnboardingHeight.vue";
import OnboardingCurrentWeight from "~/components/onboarding/screens/OnboardingCurrentWeight.vue";
import OnboardingActivityLevel from "~/components/onboarding/screens/OnboardingActivityLevel.vue";
import OnboardingTargetWeight from "~/components/onboarding/screens/OnboardingTargetWeight.vue";
import OnboardingEventDate from "~/components/onboarding/screens/OnboardingEventDate.vue";
import OnboardingInfoList from "~/components/onboarding/screens/OnboardingInfoList.vue";
import OnboardingDiet from "~/components/onboarding/screens/OnboardingDiet.vue";
import OnboardingPartner from "~/components/onboarding/screens/OnboardingPartner.vue";
import OnboardingIllustrationsRecipes from "~/components/onboarding/screens/OnboardingIllustrationsRecipes.vue";
import OnboardingVoucherCode from "~/components/onboarding/screens/OnboardingVoucherCode.vue";
import OnboardingAgeRange from "~/components/onboarding/screens/OnboardingAgeRange.vue";
import OnboardingInfoListImage from "~/components/onboarding/screens/OnboardingInfoListImage.vue";
import OnboardingAppLoadingScreen from "~/components/onboarding/screens/OnboardingAppLoadingScreen.vue";
import OnboardingOverallGoal from "~/components/onboarding/screens/OnboardingOverallGoal.vue";
import OnboardingSupportWithReviews from "~/components/onboarding/screens/OnboardingSupportWithReviews.vue";
import OnboardingDaysInRow from "~/components/onboarding/screens/OnboardingDaysInRow.vue";
import OnboardingBenefit from "~/components/onboarding/screens/OnboardingBenefits.vue";

export function useOnboarding(instructions: Instructions) {
  const { $pinia } = useNuxtApp();

  const onboardingStore = useOnboardingStore($pinia);
  const route = useRoute();
  const featureFlags = useFeatureFlags();

  const { updateRegistrationParams } = onboardingStore;
  const { registrationParams } = storeToRefs(onboardingStore);

  const screenId = computed(() => {
    if (route.params.id && typeof route.params.id === "string") {
      return route.params.id;
    }

    if (typeof instructions.start === "string") {
      return instructions.start;
    }

    return instructions.start.fallback;
  });

  const screens: { [key: string]: OnboardingScreen } = {};
  instructions.screens.forEach((item) => {
    screens[item.id] = item;
  });

  const componentMap: { [key: string]: Component } = {
    single_choice: OnboardingSingleChoice,
    multi_choice: OnboardingMultiChoice,
    overall_goal: OnboardingOverallGoal,
    affirmation: OnboardingAffirmation,
    activity_level: OnboardingActivityLevel,
    prepare_plan: OnboardingAppLoadingScreen,
    date: OnboardingEventDate,
    info_list: OnboardingInfoList,
    why_other_diets_fail: OnboardingInfoListImage,
    info_list_image: OnboardingInfoListImage,
    diet: OnboardingDiet,
    illustrations_recipes: OnboardingIllustrationsRecipes,
    support_with_reviews: OnboardingSupportWithReviews,
    days_in_row: OnboardingDaysInRow,
    voucher_code: OnboardingVoucherCode,
  };

  const staticScreens: { [key: string]: Component } = {
    sex: OnboardingSex,
    height: OnboardingHeight,
    current_weight: OnboardingCurrentWeight,
    target_weight: OnboardingTargetWeight,
    birthday: OnboardingBirthday,
    partner: OnboardingPartner,
    age_range: OnboardingAgeRange,
    benefits: OnboardingBenefit,
  };

  const screen = computed<OnboardingScreen>(() => {
    const _screen = screens[onboardingScreenSlugs[screenId.value] ?? screenId.value];
    if (!_screen) {
      throw new Error(`No screen config found for id: ${screenId.value}`);
    }
    return _screen;
  });

  const screenComponent = computed<Component>(() => {
    if (screen.value.type === "static") {
      const _staticScreenComponent = staticScreens[screen.value.staticScreenType];

      if (!_staticScreenComponent) {
        throw new Error(`No static component found for static type: ${screen.value.staticScreenType}`);
      }

      return _staticScreenComponent;
    }

    const _screenComponent = componentMap[screen.value.type];
    if (!_screenComponent) {
      throw new Error(`No component found for type: ${screen.value.type}`);
    }
    return _screenComponent;
  });

  const screenHasWideWrapper = computed(
    () =>
      ["affirmation", "prepare_plan", "info_list", "illustrations_recipes", "support_with_reviews"].includes(screen.value.type) ||
      (screen.value.type === "static" && ["benefits"].includes(screen.value.staticScreenType)),
  );

  const screenUseTransparentBackground = computed(() => screen.value.config?.useTransparentBackground || false);

  const screensNeededForCalculation = computed(() => {
    let screens = [...instructions.screens];

    screens = screens.filter((screen) => screen.type !== "prepare_plan");

    if (featureFlags.personalized_motivation_screen.isOff({ silent: true }).value) {
      screens = screens.filter(
        (screen) =>
          screen.id !== "screenid:onboarding.encouraging_flow.personal.personalized_question" &&
          screen.type !== "affirmation",
      );
    } else {
      screens = screens.filter((screen, index, self) => {
        if (screen.id.startsWith("screenid:app.profunnel.motivation.affirmation_screen")) {
          // Remove all affirmation screens except the first one
          return (
            self.findIndex((s) => s.id.startsWith("screenid:app.profunnel.motivation.affirmation_screen")) === index
          );
        }
        return true;
      });
    }

    return screens;
  });

  const screenIndex = computed(() =>
    screensNeededForCalculation.value.findIndex(
      (screen) => screen.id === screens[onboardingScreenSlugs[screenId.value] ?? screenId.value].id,
    ),
  );

  function getProgress() {
    const totalScreens = screensNeededForCalculation.value.length;

    // Special case for the main goal screen when accessed from the calculator
    if (
      screenId.value === "screenid:onboarding.encouraging_flow.goal.main_goal" &&
      route.fullPath.endsWith("calculator")
    ) {
      return Math.round((1 / totalScreens) * 100);
    }

    return Math.round((screenIndex.value / totalScreens) * 100);
  }

  const screensToReplace = ["screenid:onboarding.encouraging_flow.plan.load_plan_1"] as const;

  function screenShouldBeReplaced(currentScreenId: string): boolean {
    return screensToReplace.includes(currentScreenId);
  }

  return {
    screen,
    screenComponent,
    screens,
    registrationParams,
    updateRegistrationParams,
    screenShouldBeReplaced,
    screenHasWideWrapper,
    screenUseTransparentBackground,
    screenIndex,
    getProgress,
  };
}
