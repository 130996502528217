<script lang="ts" setup>
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = useOnboardingConditions(props);

interface Emits {
  (
    e: "next",
    value: {
      params: object;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function next() {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper :button-text="$t('registration.general.next')" @button-click="next">
  </OnboardingStickyButtonWrapper>
</template>
