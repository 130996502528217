export const onboardingScreenSlugs: Record<string, string> = {
  "age-range": "screenid:onboarding.encouraging_flow.personal.age_range",
  gender: "screenid:onboarding.encouraging_flow.personal.gender",
  "main-goal": "screenid:onboarding.encouraging_flow.goal.main_goal",
  "goal-reason": "screenid:onboarding.encouraging_flow.goal.reason",
  "additional-goal": "screenid:onboarding.encouraging_flow.goal.additional_goal",
  "past-experience": "screenid:onboarding.encouraging_flow.past.experience",
  "restrictive-diets": "screenid:onboarding.encouraging_flow.diets.restrictive",
  "sustainable-diets": "screenid:onboarding.encouraging_flow.diets.sustainable",
  "build-muscle-experience": "screenid:onboarding.encouraging_flow_build_muscle.past.experience",
  "gain-weight-experience": "screenid:onboarding.encouraging_flow_gain_weight.past.experience",
  "past-challenges": "screenid:onboarding.encouraging_flow.past.challenges",
  "challenges-overcome": "screenid:onboarding.encouraging_flow.past.challenges_affirmation",
  "weight-gain-causes": "screenid:onboarding.encouraging_flow.past.gain_causes",
  "past-changes": "screenid:onboarding.encouraging_flow.past.changes",
  "changes-differences": "screenid:onboarding.encouraging_flow.changes.differences",
  "changes-come-back": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation",
  "changes-role-model": "screenid:onboarding.encouraging_flow.changes.role_model",
  "changes-success-factors": "screenid:onboarding.encouraging_flow.changes.success_factors_affirmation",
  "calorie-counting-experience": "screenid:onboarding.encouraging_flow.calorie_counting.experience",
  "calorie-counting-yes": "screenid:onboarding.encouraging_flow.calorie_counting.yes_affirmation",
  "calorie-counting-past": "screenid:onboarding.encouraging_flow.calorie_counting.past",
  "calorie-counting-app": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation",
  "calorie-counting-app-choice": "screenid:onboarding.encouraging_flow.calorie_counting.app_choice",
  "calorie-counting-yazio": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation",
  difficulty: "screenid:onboarding.encouraging_flow.calorie_counting.difficult",
  "difficulty-yes": "screenid:onboarding.encouraging_flow.calorie_counting.difficult_yes_affirmation",
  "difficulty-no": "screenid:onboarding.encouraging_flow.calorie_counting.difficult_no_affirmation",
  "show-calorie-counting-info": "screenid:onboarding.encouraging_flow.calorie_counting.info",
  "calorie-counting-info": "screenid:onboarding.encouraging_flow.calorie_counting.info_affirmation",
  "fasting-experience": "screenid:onboarding.encouraging_flow.fasting.experience",
  "fasting-explanation": "screenid:onboarding.encouraging_flow.fasting.explanation_affirmation",
  "try-fasting": "screenid:onboarding.encouraging_flow.fasting.try",
  "fasting-positive": "screenid:onboarding.encouraging_flow.fasting.positive_affirmation",
  "mood-plan": "screenid:onboarding.encouraging_flow.mood.plan",
  "mood-journey": "screenid:onboarding.encouraging_flow.mood.journey",
  "mood-positive": "screenid:onboarding.encouraging_flow.mood.positive_affirmation",
  "mood-unsure": "screenid:onboarding.encouraging_flow.mood.unsure_affirmation",
  "mood-negative": "screenid:onboarding.encouraging_flow.mood.negative_affirmation",
  "motivation-excited": "screenid:onboarding.encouraging_flow.motivation.excited",
  "motivation-reflection": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation",
  "start-journey": "screenid:onboarding.encouraging_flow.short.start_journey_affirmation",
  "welcome-new-you": "screenid:onboarding.encouraging_flow.short.welcome_new_you_affirmation",
  "short-goal": "screenid:onboarding.encouraging_flow.short.goal",
  "yazio-help": "screenid:onboarding.encouraging_flow.short.yazio_help",
  nutrition: "screenid:onboarding.encouraging_flow.short.nutrition",
  "bad-habits": "screenid:onboarding.encouraging_flow.short.bad_habits",
  "short-barriers": "screenid:onboarding.encouraging_flow.short.barriers",
  "weight-management": "screenid:onboarding.encouraging_flow.short.weight_management_affirmation",
  birthday: "screenid:onboarding.encouraging_flow.personal.birthday",
  height: "screenid:onboarding.encouraging_flow.personal.height",
  "activity-level": "screenid:onboarding.encouraging_flow.personal.activity_level",
  "current-weight": "screenid:onboarding.encouraging_flow.personal.current_weight",
  "future-goals": "screenid:onboarding.encouraging_flow.personal_goal.look_ahead_affirmation",
  "goal-weight": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight",
  "positive-effects": "screenid:onboarding.encouraging_flow.personal_goal.positive_effects_affirmation",
  "special-event": "screenid:onboarding.encouraging_flow.personal_goal.special_event",
  "event-date": "screenid:onboarding.encouraging_flow.personal_goal.event_date",
  "set-goal": "screenid:onboarding.encouraging_flow.personal_goal.set_goal_affirmation",
  reviews: "screenid:onboarding.encouraging_flow.personal_goal.reviews_affirmation",
  "minutes-per-day": "screenid:onboarding.encouraging_flow.time.minutes_per_day",
  "low-time-usage": "screenid:onboarding.encouraging_flow.time.low_time_usage_per_day_affirmation",
  "high-time-usage": "screenid:onboarding.encouraging_flow.time.high_time_usage_per_day_affirmation",
  "days-in-a-row": "screenid:onboarding.encouraging_flow.time.days_in_a_row",
  "time-streaks": "screenid:onboarding.encouraging_flow.time.streaks_affirmation",
  "dietary-preferences": "screenid:onboarding.encouraging_flow.diet.dietary_preferences",
  recipes: "screenid:onboarding.encouraging_flow.diet.recipes_affirmation",
  trust: "screenid:onboarding.encouraging_flow.plan.trust_affirmation",
  "personalized-question": "screenid:onboarding.encouraging_flow.personal.personalized_question",
  confidence: "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
  energy: "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
  vitality: "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
  fitness: "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
  family: "screenid:app.profunnel.motivation.affirmation_screen.social_family",
  "load-plan": "screenid:onboarding.encouraging_flow.plan.load_plan_1",
  "benefits": "screenid:onboarding.encouraging_flow.plan.benefits",
};
